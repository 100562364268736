import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  EventContainer,
  EventHeader,
  EventTitle,
  EventDivider,
  DeviceEventsContainer,
  DeviceEventContainer,
  DeviceRowContainer,
  EventNameTitle,
  EventLocationTitle,
  EventDateTitle,
  EventBoothTitle,
  EventTableContainer,
} from "./EventsElements";
import "./_events.scss";

function createEvent(name, location, date, booth) {
  return { name, location, date, booth };
}

const events = [
  createEvent("Global Pet Expo", "Orlando, FL", "03/26/2025", "#3271"),
  createEvent("SUPERZOO", "Las Vegas, NV", "08/13/2025 - 08/15/2025", "#3903"),
];

export default function BasicTable() {
  return (
    <EventContainer id="events">
      <EventHeader>
        <EventDivider /> <EventTitle>Events</EventTitle>
      </EventHeader>
      {
        events.length ? (
          <>
            <DeviceEventsContainer>
              {events.map((event) => (
                <DeviceEventContainer>
                  <DeviceRowContainer>
                    <EventNameTitle>Event:</EventNameTitle> {event.name}
                  </DeviceRowContainer>
                  <DeviceRowContainer>
                    <EventLocationTitle>Location:</EventLocationTitle>{" "}
                    {event.location}
                  </DeviceRowContainer>
                  <DeviceRowContainer>
                    <EventDateTitle>Date:</EventDateTitle>
                    {event.date}
                  </DeviceRowContainer>
                  <DeviceRowContainer>
                    <EventBoothTitle>Booth: </EventBoothTitle> {event.booth}
                  </DeviceRowContainer>
                </DeviceEventContainer>
              ))}
            </DeviceEventsContainer>
            <EventTableContainer>
              <TableContainer>
                <Table
                  className="table-container"
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow className="table-row">
                      <TableCell className="table-text table-title">
                        Event
                      </TableCell>
                      <TableCell
                        className="table-text table-title"
                        align="right"
                      >
                        Location
                      </TableCell>
                      <TableCell
                        className="table-text table-title"
                        align="right"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        className="table-text table-title"
                        align="right"
                      >
                        Booth
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {events.map((event, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className="table-row"
                      >
                        <TableCell
                          className="table-text"
                          component="th"
                          scope="row"
                        >
                          {event.name}
                        </TableCell>
                        <TableCell className="table-text" align="right">
                          {event.location}
                        </TableCell>
                        <TableCell className="table-text" align="right">
                          {event.date}
                        </TableCell>
                        <TableCell className="table-text" align="right">
                          {event.booth}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </EventTableContainer>
          </>
        ) : (
          <div>No events found</div>
        ) // TODO: Add no events found message
      }
    </EventContainer>
  );
}
